import { enhancedFetch } from 'journey-ui'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export function isTablet() {
  return navigator.userAgent.includes('Mason G430') || (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) || window.localStorage.getItem('is-tablet')
}

export function money(pennies: number, sign = true) {
  return `${sign ? '$' : ''}${formatter.format(pennies / 100).replace('$', '')}`
}

export function isEven(num: number) {
  return num % 2 === 0
}

export function requiresAction(orderCard: OrderCard) {
  return ['new', 'unassigned'].includes(orderCard.status)
}

export function formatSizeName(item: Item) {
  if (item.size_name === 'Regular' || item.name === item.size_name) {
    return ''
  }

  return item.size_name
}

export function capitalize(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1, 999_999)
}

export function groupBy(rows, key) {
  // eslint-disable-next-line unicorn/no-array-reduce
  return rows.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function pushOrUpdateAction<T>(subject: T, selector = undefined) {
  return (subjects: T[]) => pushOrUpdate(subjects, subject, selector)
}

export function requiresAuth(router) {
  const noAuthRoutes = [
    '/login',
    '/',
    '/sign-up',
    '/forgot-password',
    '/reset-password',
  ]

  return !noAuthRoutes.includes(router.route)
}

export function pushOrUpdate<T>(array: T[] | undefined, subject: T, selector: ((a, b) => boolean) = (a, b) => a.hash_id === b.hash_id) {
  array = array || []

  const index = array.findIndex((a: T) => selector(a, subject))

  if (index !== -1) {
    const newArray = [...array]
    newArray[index] = subject

    return newArray
  }

  return array.concat(subject)
}

export function encodeCanvas(canvas): string {
  return canvas.toDataURL('image/jpeg', 0.1).replace(/^data:image\/(png|jpg|jpeg);base64,/, '') // remove mimetype
}
