import { useRouter } from 'next/router'
import {
  enhancedFetch,
  getEchoConfig,
  getLaravelConfig,
  isServer,
  Journey,
  JourneyApp,
  useFetch,
  usePaginate,
  useSocket,
} from 'journey-ui'
import { AppProps } from 'next/app'

import { PizzaPrinter } from 'pizza-printer'
import '../styles/app.css'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useCurrentRef } from 'journey-ui/dist/hooks/useCurrentRef'
import { KeepAwake } from '@capacitor-community/keep-awake'
import { useDebounce } from 'use-debounce'
import { Capacitor } from '@capacitor/core'
import * as Sentry from '@sentry/nextjs'
import { AppContext, AppContextData } from '../contexts/AppContext'
import { useDevice } from '../hooks/useDevice'
import { useAudio } from '../hooks/useAudio'
import { useHealthCheck } from '../hooks/useHealthCheck'
import { isTablet, requiresAuth } from '../helpers'
import { useDailyReboot } from '../hooks/useDailyReboot'
import { useCanvasPrinter } from '../hooks/useCanvasPrinter'
import { OrderPrintCanvas } from '../util/OrderPrintCanvas'
import { usePrintLogo } from '../hooks/usePrintLogo'
import { usePrinterScanner } from '../hooks/usePrinterScanner'
import { useOrderRefresherBackup } from '../hooks/useOrderRefresherBackup'

const SpinnerDots = forwardRef<any, HTMLDivElement>((props, ref) => (
  <div className="spinner-dots" ref={ref}>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
))

Journey.appendConfig({
  spinnerComponent: SpinnerDots,
  // Any usage of enhancedFetch in getServersideProps needs a domain as we can't always infer the domain like we can on clientside usage.
  serversideBaseDomain: process.env.NEXT_PUBLIC_API_DOMAIN,
  // The URL we will access to ensure upon generating a CSRF cookie. (Usually before our first POST request)
  csrfCookieUrl: '/api/sanctum/csrf-cookie',
  // Laravel support
  ...getLaravelConfig(),
  // Websocket/Pusher support
  ...getEchoConfig({
    broadcaster: 'pusher',
    cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
    forceTLS: true,
    withoutInterceptors: true,
    key: process.env.NEXT_PUBLIC_PUSHER_KEY,
    authEndpoint: '/api/broadcasting/auth',
  }),
})

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  const { menu, section, item, order, chat, page } = pageProps

  const [user, setUser, userFetcher] = useFetch<User>({
    url: '/api/auth/user',
    swrOptions: {
      revalidateOnFocus: false,
    },
  })

  useEffect(() => {
    console.log('[POS App Rendered]')
  }, [])

  useEffect(() => {
    console.log(`[POS App] URL: ${router.asPath}`)
  }, [router.asPath])

  /* Global redirect to login if not logged in. */
  useEffect(() => {
    if (userFetcher.error && requiresAuth(router)) {
      router.push(`/login?redirect_to=${router.asPath}`).catch(console.error)
    }
  }, [router, userFetcher.error])

  const brandHashId = router.query.brandHashId
    ?? order?.brand_hash_id
    ?? menu?.brand_hash_id
    ?? section?.brand_hash_id
    ?? item?.brand_hash_id
    ?? chat?.brand_hash_id
    ?? page?.brand_hash_id

  const [brand, setBrand, brandFetcher] = useFetch<Brand>({
    url: `/api/brand/${brandHashId}`,
    isAllowed: !!brandHashId,
  })

  const [fatalError, setFatalError] = useState<string>('')
  const [device, setDevice] = useDevice(brand, user, setFatalError)

  const [alertMilliseconds, setAlertMilliseconds] = useState<number | null>(null)

  const hasOwnerAccess = brand?.is_owner

  /* Our G430 tablets are not very performant, lets turn off page transitioning. */
  if (!isServer() && isTablet()) {
    Journey.appendConfig({ pageTransitions: false })
  }

  const [printers, setPrinters, printerPaginator] = usePaginate<Printer>({
    url: device ? `/api/brand/${brand?.hash_id}/printer?device_hash_id=${device.hash_id}` : `/api/brand/${brand?.hash_id}/printer`,
    isAllowed: !!brand,
  })

  const scanPrinters = usePrinterScanner()

  useEffect(() => {
    scanPrinters()
      .then((scannedDevices) => {
        printers
          .forEach((printer) => {
            const isPaired = scannedDevices.some((d) => (d.address === printer.address))

            if (isPaired) {
            // eslint-disable-next-line promise/no-nesting
              enhancedFetch(`/api/printer/${printer.hash_id}/paired`, { method: 'POST' }).catch(console.error)
            }
          })
      }).catch(console.error)
  }, [printers, scanPrinters])

  const unreadChatFetcher = useFetch<{ unread_chat_count: number }>({
    url: `/api/brand/${brand?.hash_id}/unread-chat-count`,
    isAllowed: !!brand && hasOwnerAccess,
  })

  const [searchOrdersText, setSearchOrdersText] = useState('')

  const [orderCards, setOrderCards, orderCardsPaginator] = usePaginate<OrderCard>({
    isAllowed: !!brand,
    url: `/api/brand/${brandHashId}/order-card?search_text=${useDebounce(searchOrdersText, 300)[0]}`,
    swrOptions: {

      revalidateOnFocus: false,
    },
  })

  useEffect(() => {
    if (!brand) {
      return
    }

    setAlertMilliseconds(orderCards.filter((oc) => ['new', 'unassigned'].includes(oc.status)).length > 0 ? 20_000 : null)
  }, [orderCards, brand, setAlertMilliseconds])

  /* We HTTP fetch ping in the event pusher is down or not connecting correctly. */
  useOrderRefresherBackup(brandHashId, orderCardsPaginator)

  /* If another device confirms an order, we need to communicate that across other devices. */
  useSocket({
    channelId: user && brand?.hash_id ? `brand.${brand.hash_id}` : '',
    type: 'private',
    eventId: '.order.status-changed',
    onMessage: (message) => {
      orderCardsPaginator.refresh()
    },
  })

  useSocket({
    channelId: user && device?.hash_id ? `device.${device.hash_id}` : '',
    type: 'private',
    eventId: '.ping',
    onMessage: (msg) => {
      enhancedFetch(`/api/brand/${brand.hash_id}/pong`, {
        method: 'POST',
        body: {
          device_ping_hash_id: msg.device_ping_hash_id,
          data: {
            new_order_names: orderCards.filter((oc) => oc.status === 'new').map((oc) => oc.name),
          },
        },
      }).catch(console.error)
    },
  })

  const [retrySocket, setRetrySocket] = useState(false)

  const printCanvas = useCanvasPrinter(printers)
  const printLogoSrc = usePrintLogo(brand)

  const orderPlacedSocket = useSocket({
    channelId: user && brand?.hash_id ? `brand.${brand.hash_id}` : '',
    type: 'private',
    eventId: '.order.placed',
    onSubscribe: () => {
      setFatalError('')
      setRetrySocket(false)
    },
    onError: (e) => {
      setFatalError(`Failed to properly connect to our websockets. (${e.message})`)
      setRetrySocket(true)
    },
    onStateChange: (state) => {
      if (state.current === 'connected') {
        setFatalError('')
        setRetrySocket(false)
      } else if (state.current === 'unavailable') {
        setFatalError('Currently unavailable to connect to our websockets, please verify your internet is working.')
        setRetrySocket(true)
      }
    },
    onMessage: ({ hash_id, should_auto_print }) => {
      /* Auto print when we receive an order if we have auto confirm. */
      if (should_auto_print && printers.length > 0 && Capacitor.isNativePlatform()) {
        enhancedFetch<Order>(`/api/order/${hash_id}`).then((order) => {
          const canvas = new OrderPrintCanvas(brand, order, {
            padding: printers[0]?.padding ?? 30,
            fontSize: printers[0]?.font_size ?? 28,
            widthSize: printers[0]?.width_size ?? 80,
          })
          canvas.printLogo = printLogoSrc

          return printCanvas(canvas)
        }).catch((error) => {
          Sentry.captureException(error)
          console.error(error)
        })
      }

      /* Upon receiving a new order, lets immediately refresh so the restaurant can see the new order. */
      orderCardsPaginator.refresh()
    },
  })

  const orderPlacedSocketRef = useRef<any>()
  orderPlacedSocketRef.current = orderPlacedSocket

  useEffect(() => {
    if (!retrySocket) {
      return
    }

    const t = setTimeout(() => {
      setRetrySocket(false)
      orderPlacedSocketRef.current.connect()
    }, 30_000)

    return () => clearTimeout(t)
  }, [orderPlacedSocketRef, retrySocket])

  useSocket({
    channelId: user && brand?.hash_id ? `brand.${brand.hash_id}` : '',
    type: 'private',
    eventId: '.order.cancelled',
    onMessage: (message) => {
      /* Upon receiving a new order, lets immediately refresh so the restaurant can see the new order. */
      orderCardsPaginator.refresh()
    },
  })

  const unreadChatFetcherRef = useCurrentRef(unreadChatFetcher)
  const refreshUnreadChatCount = useCallback(() => unreadChatFetcherRef.current.refresh(), [unreadChatFetcherRef])

  const [bankErrorData, setBankErrorData] = useState<BankErrorData>({
    has_error: false,
    loaded: false,
    brand_hash_id: '',
    bank_account_url: '',
  })

  const contextProps: AppContextData = {
    unreadChatCount: unreadChatFetcher.data?.unread_chat_count ?? 0,
    refreshUnreadChatCount,
    searchOrdersText,
    setSearchOrdersText,
    orderCardsPaginator,
    orderCards,
    user,
    setUser,
    device,
    printers,
    printerPaginator,
    setDevice,
    brandHashId,
    alertMilliseconds,
    setAlertMilliseconds,
    brand,
    setBrand,
    bankErrorData,
    setBankErrorData,
    fatalError,
    setFatalError,
    hasOwnerAccess,
  }

  const isTabletAlwaysAwake = brand?.is_tablet_always_awake

  useEffect(() => {
    if (isTabletAlwaysAwake) {
      KeepAwake.keepAwake()
        .catch(console.error)
    }
  }, [isTabletAlwaysAwake])

  const orderAlertAudio = useAudio('https://s3.amazonaws.com/pizzamico/pos/sounds/order-alert-gained.ogg')

  const orderAlertAudioRef = useRef<HTMLAudioElement>()
  orderAlertAudioRef.current = orderAlertAudio

  useEffect(() => {
    if (!alertMilliseconds) {
      return
    }

    const playAlert = () => {
      orderAlertAudioRef.current?.play().catch(console.error)
      PizzaPrinter.unlockScreen().catch(console.error)
    }

    const t = setInterval(() => playAlert(), 20_000)
    playAlert()

    return () => clearInterval(t)
  }, [orderAlertAudioRef, alertMilliseconds])

  useHealthCheck(brand, device)
  useDailyReboot()

  return (
    <AppContext.Provider value={contextProps}>
      <JourneyApp routePath={router.asPath}>
        <Component {...pageProps} />
      </JourneyApp>
    </AppContext.Provider>
  )
}

export default App
